const META_V1_4 = {
    title: 'v1.4 版本说明 - 博友圈 · 博客人的朋友圈！',
    keywords: '博友圈, 版本说明, v1.4',
    description: '博友圈 v1.4 版本说明。'
}

const RELEASE_INFO_V1_4 = {
    title: 'v1.4 版本说明',
    content: <>
        <p>博友圈于 2023 年 7 月 15 日发布了 v1.4 版本！该版本增加了一个新的页面 —— 「博客详情」，用于展示单个博客的详细信息，包括：博客基本信息、总体统计信息、浏览统计信息和收录文章列表。</p>

        <h3>1 功能说明</h3>
        <p>该版本新增加的「博客详情」页面用于展示单个博客的详细信息，包括：</p>
        <ul>
            <li>博客基本信息：博客的原始信息，即博主头像、博客名称、博客域名和博客描述；</li>
            <li>总体统计信息：该博客被本站收录后的运转信息，即收录时间、文章收录总数、文章浏览总数和最近更新时间；</li>
            <li>浏览统计信息：最近一个月该博客从本站跳出的文章浏览统计图表；</li>
            <li>收录文章列表：本站所收录的该博客的所有文章。</li>
        </ul>
        「博客详情」页面可以从两个地方进入，一是从「首页」最新文章列表里点击单个条目的博主头像或博客名称进入；二是从「博客广场」博客列表里点击单个博客条目的博主头像或博客名称进入。

        <h3>2 页面截图</h3>
        <p>博客详情页</p>
        <p><img border="1" src="/assets/images/sites/release_notes/v1.4/blog-detail-page.png" /></p>
    </>,
    publishedAt: '2023年7月15日'
}

export { META_V1_4, RELEASE_INFO_V1_4 };