const META_V1_2 = {
    title: 'v1.2 版本说明 - 博友圈 · 博客人的朋友圈！',
    keywords: '博友圈, 版本说明, v1.2',
    description: '博友圈 v1.2 版本说明。'
}

const RELEASE_INFO_V1_2 = {
    title: 'v1.2 版本说明',
    content: <>
        <p>博友圈于 2023 年 7 月 10 日发布了 v1.2 版本！该版本主要增加了「星球穿梭」页面。</p>
        <h3>1 功能说明</h3>
        <p>访问「星球穿梭」页面可以随机穿梭到一位博友的星球，即该页面会从博友圈所收录的博客中随机找一个，等待几秒钟后跳转过去。</p>
        <h3>2 页面截图</h3>
        <p>星球穿梭页</p>
        <p><img border="1" src="/assets/images/sites/release_notes/v1.2/planet-shuttle-page.png" /></p>
    </>,
    publishedAt: '2023年7月10日'
}

export { META_V1_2, RELEASE_INFO_V1_2 };